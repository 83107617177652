import { Outlet } from "react-router-dom";
import SideBar from "../components/sidebar";

const Layout = () => {
  return (
    <div style={{ position: "relative" }}>
      <SideBar />
      <div
        className="p-3 scrollbar"
        style={{
          marginLeft: "300px",
          height: "100vh",
          maxHeight: "100vh",
          overflowY: "scroll",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
