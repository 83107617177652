import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { MdOutlineRefresh } from "react-icons/md";
import axios from "axios";
import toast from "react-hot-toast";

const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const navigate = useNavigate();

  const [total, setTotal] = useState(0); // For total bookings
  const [page, setPage] = useState(1); // Current page
  const [limit] = useState(10); // Limit of bookings per page

  const totalPages = Math.ceil(total / limit); // Calculate total pages

  const maxPagesToShow = 5; // Maximum number of pages to show at a time
  const startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

  const [searchById, setSearchById] = useState("");
  const [searchByName, setSearchByName] = useState("");
  const [searchByStatus, setSearchByStatus] = useState("");

  useEffect(() => {
    fetch(
      `https://api.pegasuschauffeurdrive.com/api/booking?page=${page}&limit=${limit}&bookingId=${searchById.toLocaleLowerCase()}&clientFirstName=${searchByName}&bookingStatus=${searchByStatus}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          // Handle HTTP errors
          throw new Error("Network response was not ok");
        }
        if (response.status === 204) {
          // Handle no content response
          return { bookings: [], total: 0 };
        }
        return response.json();
      })
      .then((response) => {
        if (response.errors) {
          console.error(response.errors);
          return;
        }
        setBookings(response.bookings);
        setTotal(response.total);
      });
    setRefreshData(false);
  }, [refreshData, page, limit]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setRefreshData(true); // Refresh data on page change
  };

  const handleSeacrhByIDChange = (event) => {
    setSearchById(event.target.value);
  };

  const handleSeacrhByNameChange = (event) => {
    setSearchByName(event.target.value);
  };

  const handleSeacrhByStatusChange = (event) => {
    setSearchByStatus(event.target.value);
  };

  const handleSearchSubmit = () => {
    console.log(searchById);
    console.log(searchByName);
    setPage(1);
    setRefreshData(true);
  };

  const handleSearchReset = () => {
    setSearchById("");
    setSearchByName("");
    setSearchByStatus("");
    setPage(1);
    setRefreshData(true);
  };

  const handleBookingComplete = async (id) => {
    try {
      const res = await axios.put(
        `https://api.pegasuschauffeurdrive.com/api/booking/${id}`,
        {
          bookingStatus: "Completed",
        }
      );

      if (res.data.booking) {
        toast.success("Booking marked as completed");
        setRefreshData(true);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };


  return (
    <div>
      <div style={{ height: "52px" }}>
        {/* <span className="fs-1 fw-bold" style={{ color: "#fff" }}>
          Bookings
        </span> */}
        <h1 className="fw-bold" style={{ color: "#fff" }}>
          Bookings
        </h1>
      </div>
      <hr style={{ color: "#fff", borderTop: "3px solid" }} />
      <div className="d-flex justify-content-between my-3">
        <Form>
          <Row className="row-cols-lg-auto g-3 align-items-center">
            <Col>
              <Input
                id="searchById"
                name="searchById"
                placeholder="Search by ID"
                type="text"
                value={searchById}
                onChange={handleSeacrhByIDChange}
              />
            </Col>
            <Col>
              <Input
                id="searchByName"
                name="searchByName"
                placeholder="Search by first name"
                type="text"
                value={searchByName}
                onChange={handleSeacrhByNameChange}
              />
            </Col>
            <Col>
              <Input
                id="searchByStatus"
                name="searchByStatus"
                placeholder="Search by Status"
                type="text"
                value={searchByStatus}
                onChange={handleSeacrhByStatusChange}
              />
            </Col>
            <Col>
              <Button
                style={{
                  background: "none",
                  color: "#fff",
                  border: "2px solid #fff",
                }}
                onClick={handleSearchSubmit}
              >
                Search
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  background: "none",
                  color: "#fff",
                  border: "2px solid #fff",
                }}
                onClick={handleSearchReset}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
        <Button
          style={{
            background: "none",
            color: "#fff",
            border: "2px solid #fff",
          }}
          onClick={() => setRefreshData(true)}
        >
          <MdOutlineRefresh className="fs-4" /> Refresh
        </Button>
      </div>
      <Table hover responsive>
        <thead className="align-top">
          <tr>
            <th>Sr. No.</th>
            <th>Booking ID</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Booking Status</th>
            <th>Booking Date</th>
            <th>Booking Time</th>
            <th>Booking Details</th>
            <th>Booking Completed</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {bookings.length > 0 ? (
            bookings?.map((booking, index) => (
              <tr key={booking._id}>
                <td>{index + 1}</td>
                <td className="text-uppercase fw-bold">{booking.bookingId}</td>
                <td>{`${booking.clientFirstName} ${booking.clientLastName}`}</td>
                <td>{booking.clientEmail}</td>
                <td>{booking.clientPhoneNumber}</td>
                <td>
                  <Badge
                    color={
                      booking.bookingStatus === "Pending"
                        ? "warning"
                        : booking.bookingStatus === "Accepted"
                        ? "success"
                        : booking.bookingStatus === "Rejected"
                        ? "danger"
                        : booking.bookingStatus === "Completed"
                        ? "info"
                        : "dark"
                    }
                    className="fs-6"
                  >
                    {booking.bookingStatus}
                  </Badge>
                </td>
                <td>
                  {new Date(booking?.pickUpDateTime).toLocaleDateString()}
                </td>
                <td>
                  {new Date(booking?.pickUpDateTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </td>
                <td>
                  <Button
                    color="dark"
                    onClick={() => {
                      navigate(`/bookings/${booking._id}`);
                    }}
                  >
                    View Details
                  </Button>
                </td>
                <td>
                  {booking?.bookingStatus === "Accepted" && (
                    <Button
                      color="success"
                      disabled={
                        booking.bookingStatus === "Completed" ? true : false
                      }
                      onClick={() => handleBookingComplete(booking?._id)}
                    >
                      Complete Booking
                    </Button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10} className="text-center fs-3 text-danger">
                No Bookings Available!
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination */}
      <Pagination
        aria-label="Page navigation"
        className="d-flex justify-content-center align-items-center"
      >
        <PaginationItem className="p-2" disabled={page <= 1}>
          <PaginationLink
            style={{
              background: "transparent",
              color: "#fff",
              border: "2px solid #b88d48",
              borderRadius: "25%",
            }}
            previous
            onClick={() => handlePageChange(page - 1)}
          />
        </PaginationItem>

        {Array.from(
          { length: endPage - startPage + 1 },
          (_, index) => startPage + index
        ).map((pageNumber) => (
          <PaginationItem
            className="p-2"
            active={page === pageNumber}
            key={pageNumber}
          >
            <PaginationLink
              style={{
                background: "transparent",
                color: "#fff",
                border: "2px solid #b88d48",
                borderRadius: "25%",
              }}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem className="p-2" disabled={page >= totalPages}>
          <PaginationLink
            style={{
              background: "transparent",
              color: "#fff",
              border: "2px solid #b88d48",
              borderRadius: "25%",
            }}
            next
            onClick={() => handlePageChange(page + 1)}
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default Bookings;
