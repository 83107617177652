import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const Login = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is Required!"),
    password: Yup.string()
      .min(8, "Password must contain at least 8 characters")
      .required("Password is Required!"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const res = await axios.post(
        "https://api.pegasuschauffeurdrive.com/api/auth/login",
        {
          ...values,
        }
      );

      if (res) {
        const { data } = res;
        if (data.user) {
          console.log(data);
          localStorage.setItem("user", JSON.stringify(data.user));
          localStorage.setItem("token", data.token);
          localStorage.setItem("isAuthenticated", data.token && true);
          toast.success("User Logged In Successfully.");
          navigate("/");
        } else {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="bg-black">
      {/* <Header /> */}
      <div className="container login-form">
        <div className="vh-100 row align-items-center justify-content-center">
          <div className="col-11 col-md-8 col-lg-5 py-5 px-4 custom-form">
            <div className="mb-4 text-center">
              <img
                src="/images/logo.png"
                alt="Pegasus Chauffeur Logo"
                className="img-fluid"
                width={250}
              />
              {/* <h1 className="text-center mb-4">Pegasus Chauffeur</h1> */}
            </div>
            <hr style={{ color: "#B88D48", borderTop: "3px solid" }} />
            <div>
              <h3 className="text-center my-4" style={{ color: "#B88D48" }}>
                Admin Login
              </h3>
            </div>
            <hr style={{ color: "#B88D48", borderTop: "3px solid" }} />
            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}
              >
                <Form className="my-5">
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label"
                      style={{ color: "#B88D48" }}
                    >
                      Email address
                    </label>
                    <Field
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="abc.xyz@example.com"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="password"
                      className="form-label"
                      style={{ color: "#B88D48" }}
                    >
                      Password
                    </label>
                    <Field
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="********"
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="error-message"
                    />
                  </div>
                  {/* <p className="text-end mt-4">Forgot password?</p> */}
                  <button
                    type="submit"
                    className="btn btn-primary login-btn w-100"
                  >
                    Submit
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
