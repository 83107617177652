import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Badge,
  Alert,
  Button,
} from "reactstrap";
import { MdClear, MdDone, MdOutlineArrowBack } from "react-icons/md";
import toast from "react-hot-toast";

const BookingDetails = () => {
  const [booking, setBooking] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleAcceptBooking = async (id) => {
    try {
      const response = await fetch(
        `https://api.pegasuschauffeurdrive.com/api/booking/${id}/approve`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((response) => response.json());

      toast.success(response.message);
      setRefresh(true);
    } catch (error) {
      toast.error("Failed to send email.");
    }
  };

  const handleRejectBooking = async (id) => {
    try {
      const response = await fetch(
        `https://api.pegasuschauffeurdrive.com/api/booking/${id}/reject`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((response) => response.json());

      toast.success(response.message);
      setRefresh(true);
    } catch (error) {
      toast.error("Failed to send email.");
    }
  };

  useEffect(() => {
    fetch(`https://api.pegasuschauffeurdrive.com/api/booking/${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.errors) {
          console.error(response.errors);
        }
        setBooking(response.booking);
        setRefresh(false);
      });
  }, [id, refresh]);

  console.log(booking);
  return (
    <Container style={{ color: "#fff" }}>
      <div style={{ height: "52px" }}>
        {/* <span className="fs-1 fw-bold" style={{ color: "#fff" }}>
          Bookings
        </span> */}
        <h1 className="fw-bold" style={{ color: "#fff" }}>
          Booking Detail
        </h1>
      </div>
      <hr style={{ color: "#fff", borderTop: "3px solid" }} />

      <Row className="mb-4">
        <Col md={4}>
          <Button
            style={{
              background: "none",
              color: "#fff",
              border: "2px solid #fff",
            }}
            onClick={() => {
              navigate("/bookings");
            }}
          >
            <MdOutlineArrowBack /> Back
          </Button>
        </Col>
        <Col md={4} className="text-center">
          <span className="fs-5 fw-bold">
            Booking Status{" "}
            <Badge
              color={
                booking?.bookingStatus === "Pending"
                  ? "warning"
                  : booking?.bookingStatus === "Accepted"
                  ? "success"
                  : booking?.bookingStatus === "Rejected"
                  ? "danger"
                  : booking?.bookingStatus === "Completed"
                  ? "info"
                  : "dark"
              }
            >
              {booking?.bookingStatus}
            </Badge>
          </span>
        </Col>
        <Col md={4} className="text-end">
          <Button
            color="danger"
            className="me-3"
            onClick={() => handleRejectBooking(booking?._id)}
            disabled={booking?.bookingStatus !== "Pending"}
          >
            Reject <MdClear className="fs-5" />
          </Button>
          <Button
            color="success"
            onClick={() => handleAcceptBooking(booking?._id)}
            disabled={booking?.bookingStatus !== "Pending"}
          >
            Accept <MdDone className="fs-5" />
          </Button>
        </Col>
      </Row>

      <Form className="mb-5">
        <h3 className="fw-bold">General Details</h3>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="clientFirstName" className="fw-bold">
                First Name
              </Label>
              <Input
                id="clientFirstName"
                name="clientFirstName"
                type="text"
                defaultValue={booking?.clientFirstName}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="clientLastName" className="fw-bold">
                Last Name
              </Label>
              <Input
                id="clientLastName"
                name="clientLastName"
                type="text"
                defaultValue={booking?.clientLastName}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="clientEmailAddress" className="fw-bold">
                Email
              </Label>
              <Input
                id="clientEmailAddress"
                name="clientEmailAddress"
                type="text"
                defaultValue={booking?.clientEmail}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="clientPhoneNumber" className="fw-bold">
                Phone Number
              </Label>
              <Input
                id="clientPhoneNumber"
                name="clientPhoneNumber"
                type="text"
                defaultValue={booking?.clientPhoneNumber}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <h3 className="mt-4 fw-bold">Journey Details</h3>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="serviceType" className="fw-bold">
                Service Type
              </Label>
              <Input
                id="serviceType"
                name="serviceType"
                type="text"
                defaultValue={booking?.serviceType}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="transferType" className="fw-bold">
                Transfer Type
              </Label>
              <Input
                id="transferType"
                name="transferType"
                type="text"
                defaultValue={booking?.transferType}
                disabled={true}
                className={`${
                  booking?.transferType === "Round Trip" && "bg-info"
                }`}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="routeFrom" className="fw-bold">
                Pick Up Location
              </Label>
              <Input
                id="routeFrom"
                name="routeFrom"
                type="text"
                defaultValue={booking?.pickUpLocation}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="routeTo" className="fw-bold">
                Drop Off Location
              </Label>
              <Input
                id="routeTo"
                name="routeTo"
                type="text"
                defaultValue={booking?.dropOffLocation}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="pickupDate" className="fw-bold">
                Pickup Date & Time
              </Label>
              <Input
                id="pickupDate"
                name="pickupDate"
                type="text"
                defaultValue={booking?.pickUpDateTime}
                disabled={true}
              />
            </FormGroup>
          </Col>
          {booking?.serviceType === "distance" && (
            <>
              <Col md={3}>
                <FormGroup>
                  <Label for="distance" className="fw-bold">
                    Distance
                  </Label>
                  <Input
                    id="distance"
                    name="distance"
                    type="text"
                    defaultValue={booking?.tripDistance}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="duration" className="fw-bold">
                    Duration
                  </Label>
                  <Input
                    id="duration"
                    name="duration"
                    type="text"
                    defaultValue={booking?.tripDuration}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
            </>
          )}
          {booking?.serviceType === "hourly" && (
            <Col md={6}>
              <FormGroup>
                <Label for="hours" className="fw-bold">
                  Hours
                </Label>
                <Input
                  id="hours"
                  name="hours"
                  type="text"
                  defaultValue={booking?.hourlyTripHours}
                  disable={true}
                />
              </FormGroup>
            </Col>
          )}
        </Row>

        {booking?.transferType === "Round Trip" && (
          <>
            <h3 className="mt-4 fw-bold">Return Journey Details</h3>
            <hr />
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="retrunRouteFrom" className="fw-bold">
                    Return Pick Up Location
                  </Label>
                  <Input
                    id="retrunRouteFrom"
                    name="retrunRouteFrom"
                    type="text"
                    defaultValue={booking?.dropOffLocation}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="retrunRouteTo" className="fw-bold">
                    Retrun Drop Off Location
                  </Label>
                  <Input
                    id="retrunRouteTo"
                    name="retrunRouteTo"
                    type="text"
                    defaultValue={booking?.pickUpLocation}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="returnDate" className="fw-bold">
                    Return Pickup Date & Time
                  </Label>
                  <Input
                    id="returnDate"
                    name="returnDate"
                    type="text"
                    defaultValue={booking?.returnBookingDate}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="distance" className="fw-bold">
                    Distance
                  </Label>
                  <Input
                    id="distance"
                    name="distance"
                    type="text"
                    defaultValue={booking?.tripDistance}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="duration" className="fw-bold">
                    Duration
                  </Label>
                  <Input
                    id="duration"
                    name="duration"
                    type="text"
                    defaultValue={booking?.tripDistance}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        <h3 className="mt-4 fw-bold">Vehicle Details</h3>
        <hr />
        <FormGroup row>
          <Label for="name" className="fw-bold" sm={2}>
            Vehicle Name
          </Label>
          <Col sm={10}>
            <Input
              id="name"
              name="name"
              type="text"
              defaultValue={booking?.bookedVehicleName}
              disabled={true}
            />
          </Col>
        </FormGroup>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="passengers" className="fw-bold">
                Passengers
              </Label>
              <Input
                id="passengers"
                name="passengers"
                type="text"
                defaultValue={booking?.passengers}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="handCarry" className="fw-bold">
                No of Hand Carry
              </Label>
              <Input
                id="handCarry"
                name="handCarry"
                type="text"
                defaultValue={booking?.handCarry}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="suitcases" className="fw-bold">
                No of Suitcases
              </Label>
              <Input
                id="suitcases"
                name="suitcases"
                type="text"
                defaultValue={booking?.suitcases}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>
        {booking?.extras?.isChildSeatSelected ||
        booking?.extras?.isChampagneSelected ||
        booking?.extras?.isWeddingRibbonSelected ? (
          <Alert color="danger">
            <h5 className="fw-bold">
              Extras <Badge color="danger">Important</Badge>
            </h5>
            <Row>
              {booking?.extras?.isChildSeatSelected && (
                <>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="childSeatCount0_12" className="fw-bold">
                        Child Seat (0 - 3 Months)
                      </Label>
                      <Input
                        id="childSeatCount0_12"
                        name="childSeatCount0_12"
                        type="text"
                        defaultValue={booking.extras.childSeat0To3MonthsCount}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="childSeatCount1_4" className="fw-bold">
                        Child Seat (4 - 6 Months)
                      </Label>
                      <Input
                        id="childSeatCount1_4"
                        name="childSeatCount1_4"
                        type="text"
                        defaultValue={booking.extras.childSeat4To6MonthsCount}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="childSeatCount4_12" className="fw-bold">
                        Child Seat (6 - 12 Months)
                      </Label>
                      <Input
                        id="childSeatCount4_12"
                        name="childSeatCount4_12"
                        type="text"
                        defaultValue={booking.extras.childSeat6To12MonthsCount}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              {booking?.extras?.isChampagneBottleSelected && (
                <Col md={6}>
                  <FormGroup>
                    <Label for="champagneCount" className="fw-bold">
                      Champagne
                    </Label>
                    <Input
                      id="champagneCount"
                      name="champagneCount"
                      type="text"
                      defaultValue={booking.extras.champagneBottleCount}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {booking?.extras?.isWeddingRibbonSelected && (
                <Col md={6}>
                  <FormGroup>
                    <Label for="weddingRibbonCount" className="fw-bold">
                      Wedding Ribbon
                    </Label>
                    <Input
                      id="weddingRibbonCount"
                      name="weddingRibbonCount"
                      type="text"
                      defaultValue={
                        booking.extras.isWeddingRibbonSelected === true && "Yes"
                      }
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </Alert>
        ) : null}

        <h3 className="mt-4 fw-bold">Flight Details</h3>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="flightDetails" className="fw-bold">
                Flight Number
              </Label>
              <Input
                id="flightDetails"
                name="flightDetails"
                type="text"
                defaultValue={booking?.flightNumber}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="airlineName" className="fw-bold">
                Airline Name
              </Label>
              <Input
                id="airlineName"
                name="airlineName"
                type="text"
                defaultValue={booking?.airlineName}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="flightArrivalTime" className="fw-bold">
                Flight Arrival Time
              </Label>
              <Input
                id="flightArrivalTime"
                name="flightArrivalTime"
                type="text"
                defaultValue={booking?.flightArrivalTime}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="flightDepartureTime" className="fw-bold">
                Flight Departure Time
              </Label>
              <Input
                id="flightDepartureTime"
                name="flightDepartureTime"
                type="text"
                defaultValue={booking?.flightDepartureTime}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="nameBoard" className="fw-bold">
                Name Board
              </Label>
              <Input
                id="nameBoard"
                name="nameBoard"
                type="text"
                defaultValue={booking?.nameboard}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="sendDriverInside" className="fw-bold">
                Send Driver Inside
              </Label>
              <Input
                id="sendDriverInside"
                name="sendDriverInside"
                type="text"
                defaultValue={booking?.sendDriverInside}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <h3 className="mt-4 fw-bold">Payment Details</h3>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="toPay" className="fw-bold">
                To Pay
              </Label>
              <Input
                id="toPay"
                name="toPay"
                type="text"
                defaultValue={booking?.bookingPrice}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="paymentMethod" className="fw-bold">
                Payment Method
              </Label>
              <Input
                id="paymentMethod"
                name="paymentMethod"
                type="text"
                defaultValue={booking?.paymentMethod}
                disabled={true}
              />
            </FormGroup>
          </Col>
          {/* <Col md={4}>
            <FormGroup>
              <Label for="transactionStatus" className="fw-bold">
                Transaction Status
              </Label>
              <Input
                id="transactionStatus"
                name="transactionStatus"
                type="text"
                defaultValue={booking?.transactionStatus}
                disabled={true}
              />
            </FormGroup>
          </Col> */}
        </Row>

        <h3 className="mt-4 fw-bold">Other Details</h3>
        <hr />
        <Row>
          <Col>
            <FormGroup>
              <Label for="additionalComments" className="fw-bold">
                Additional Comments
              </Label>
              <Input
                id="additionalComments"
                name="additionalComments"
                type="textarea"
                defaultValue={booking?.additionalComments}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default BookingDetails;
