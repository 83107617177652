import { Route, Routes } from "react-router-dom";
import Login from "../components/login";
import ProtectedRoutes from "./protectedRoutes";
import PublicRoutes from "./publicRoutes";
import Layout from "../layout";
import Dashboard from "../views/Dashboard.view";
import Bookings from "../views/Bookings.view";
import BookingDetails from "../views/BookingDetails.view";
import OldBookings from "../views/OldBookings.view";
import OldBookingDetail from "../views/OldBookingDetail.view";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />}></Route>
          <Route path="dashboard" element={<Dashboard />}></Route>
          <Route path="bookings" element={<Bookings />}></Route>
          <Route path="bookings/:id" element={<BookingDetails />}></Route>
          <Route path="old-bookings" element={<OldBookings />}></Route>
          <Route path="old-bookings/:id" element={<OldBookingDetail />}></Route>
        </Route>
      </Route>
      <Route element={<PublicRoutes />}>
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
