import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Table } from "reactstrap";
import { MdOutlineRefresh } from "react-icons/md";

const bookingQuery = `
  {
    bookingCollection {
      items {
        sys {
          id
        }
        id
        status
        serviceType
        transferType
        pickupDate
        toPay
        vehiclePrice
        hours
        distance
        duration
        routeFrom
        routeTo
        vehicle {
          ... on Vehicle {
            sys {
              id
            }
            name
          }
        }
        extras
        clientFirstName
        clientLastName
        clientEmailAddress
        clientPhoneNumber
        passengers
        suitcases
        nameBoard
        flightDetails
        paymentMethod
        transactionId
        transactionStatus
        transactionDate
        transactionDetail
        bookingApprovedOnce
        bookingRejectedOnce
      }
    }
  }
`;

const ContentfulAccessToken = `Bearer BFP5BxWGezrmeLe8ZS7b7yd-X38VtsLzgSes1kGWHp4`;

const OldBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://graphql.contentful.com/content/v1/spaces/z4msozppsnsv/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: ContentfulAccessToken,
      },
      body: JSON.stringify({ query: bookingQuery }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setBookings(data.bookingCollection.items);
      });
    setRefreshData(false);
  }, [refreshData]);

  return (
    <div className="px-4">
      <h1 className="text-center my-4 fw-bold">Bookings</h1>
      <div className="text-end my-2">
        <Button color="dark" onClick={() => setRefreshData(true)}>
          <MdOutlineRefresh className="fs-4" /> Refresh
        </Button>
      </div>
      <Table bordered hover responsive>
        <thead className="align-top">
          <tr>
            <th>Sr. No.</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Booking Status</th>
            <th>Booking Details</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {bookings?.map((booking, index) => (
            <tr key={booking.id}>
              <th>{index + 1}</th>
              <td>{`${booking.clientFirstName} ${booking.clientLastName}`}</td>
              <td>{booking.clientEmailAddress}</td>
              <td>{booking.clientPhoneNumber}</td>
              <td>
                <Badge
                  color={
                    booking.status === "Pending"
                      ? "warning"
                      : booking.status === "Approved"
                      ? "success"
                      : booking.status === "Rejected"
                      ? "danger"
                      : ""
                  }
                  className="fs-6"
                >
                  {booking.status}
                </Badge>
              </td>
              <td>
                <Button
                  color="dark"
                  onClick={() => {
                    navigate(`/old-bookings/${booking.sys.id}`);
                  }}
                >
                  View Details
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default OldBookings;
