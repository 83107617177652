import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Badge,
  Alert,
  Button,
} from "reactstrap";
import { MdClear, MdDone, MdOutlineArrowBack } from "react-icons/md";
import toast from "react-hot-toast";

const bookingQuery = `
  query GetBookingByID($bookingID: String!) {
    bookingCollection(where: { sys: { id: $bookingID } }) {
      items {
        sys {
          id
        }
        id
        status
        serviceType
        transferType
        pickupDate
        returnDate
        toPay
        vehiclePrice
        hours
        distance
        duration
        routeFrom
        routeTo
        extras
        clientFirstName
        clientLastName
        clientEmailAddress
        clientPhoneNumber
        passengers
        suitcases
        handCarry
        nameBoard
        flightDetails
        paymentMethod
        transactionId
        transactionStatus
        transactionDate
        transactionDetail
        bookingApprovedOnce
        bookingRejectedOnce
        additionalComments
        sendDriverInside
        airlineName
        flightArrivalTime
        flightDepartureTime
        vehicle {
          ... on Vehicle {
            sys {
              id
            }
            name
          }
        }
      }
    }
  }
`;

const contentFullAccessToken = `Bearer BFP5BxWGezrmeLe8ZS7b7yd-X38VtsLzgSes1kGWHp4`;

const OldBookingDetail = () => {
  const [booking, setBooking] = useState([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const handleAcceptBooking = async (id) => {
    const data = {
      id: id,
    };

    try {
      const response = await fetch(
        `https://api.pegasuschauffeurdrive.com/send-approved-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      ).then((response) => response.json());

      toast.success(response.message);
    } catch (error) {
      toast.error("Failed to send email.");
    }
  };

  const handleRejectBooking = async (id) => {
    const data = {
      id: id,
    };

    try {
      const response = await fetch(
        `https://api.pegasuschauffeurdrive.com/send-rejected-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      ).then((response) => response.json());

      toast.success(response.message);
    } catch (error) {
      toast.error("Failed to send email.");
    }
  };

  useEffect(() => {
    fetch(`https://graphql.contentful.com/content/v1/spaces/z4msozppsnsv/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: contentFullAccessToken,
      },
      body: JSON.stringify({
        query: bookingQuery,
        variables: { bookingID: id },
      }),
    })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setBooking(data.bookingCollection.items[0]);
      });
  }, [id]);

  console.log(booking);
  return (
    <Container>
      <h1 className="text-center my-4 fw-bold">Booking Details</h1>

      <Row className="mb-4">
        <Col md={4}>
          <Button
            color="dark"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <MdOutlineArrowBack /> Back
          </Button>
        </Col>
        <Col md={4} className="text-center">
          <span className="fs-5 fw-bold">
            Booking Status{" "}
            <Badge
              color={
                booking?.status === "Pending"
                  ? "warning"
                  : booking?.status === "Approved"
                  ? "success"
                  : booking?.status === "Rejected"
                  ? "danger"
                  : ""
              }
            >
              {booking.status}
            </Badge>
          </span>
        </Col>
        <Col md={4} className="text-end">
          <Button
            color="danger"
            className="me-3"
            onClick={() => handleRejectBooking(booking?.sys?.id)}
            disabled={
              booking?.bookingApprovedOnce || booking?.bookingRejectedOnce
                ? true
                : false
            }
          >
            Reject <MdClear className="fs-5" />
          </Button>
          <Button
            color="success"
            onClick={() => handleAcceptBooking(booking?.sys?.id)}
            disabled={
              booking?.bookingApprovedOnce || booking?.bookingRejectedOnce
                ? true
                : false
            }
          >
            Accept <MdDone className="fs-5" />
          </Button>
        </Col>
      </Row>

      <Form className="mb-5">
        <h3 className="fw-bold">General Details</h3>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="clientFirstName" className="fw-bold">
                First Name
              </Label>
              <Input
                id="clientFirstName"
                name="clientFirstName"
                type="text"
                defaultValue={booking?.clientFirstName}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="clientLastName" className="fw-bold">
                Last Name
              </Label>
              <Input
                id="clientLastName"
                name="clientLastName"
                type="text"
                defaultValue={booking?.clientLastName}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="clientEmailAddress" className="fw-bold">
                Email
              </Label>
              <Input
                id="clientEmailAddress"
                name="clientEmailAddress"
                type="text"
                defaultValue={booking?.clientEmailAddress}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="clientPhoneNumber" className="fw-bold">
                Phone Number
              </Label>
              <Input
                id="clientPhoneNumber"
                name="clientPhoneNumber"
                type="text"
                defaultValue={booking?.clientPhoneNumber}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <h3 className="mt-4 fw-bold">Journey Details</h3>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="serviceType" className="fw-bold">
                Service Type
              </Label>
              <Input
                id="serviceType"
                name="serviceType"
                type="text"
                defaultValue={booking?.serviceType}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="transferType" className="fw-bold">
                Transfer Type
              </Label>
              <Input
                id="transferType"
                name="transferType"
                type="text"
                defaultValue={booking?.transferType}
                disabled={true}
                className={`${
                  booking?.transferType === "Round Trip" && "bg-info"
                }`}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="routeFrom" className="fw-bold">
                Pick Up Location
              </Label>
              <Input
                id="routeFrom"
                name="routeFrom"
                type="text"
                defaultValue={booking?.routeFrom}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="routeTo" className="fw-bold">
                Drop Off Location
              </Label>
              <Input
                id="routeTo"
                name="routeTo"
                type="text"
                defaultValue={booking?.routeTo}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="pickupDate" className="fw-bold">
                Pickup Date & Time
              </Label>
              <Input
                id="pickupDate"
                name="pickupDate"
                type="text"
                defaultValue={booking?.pickupDate}
                disabled={true}
              />
            </FormGroup>
          </Col>
          {booking?.serviceType === "distance" && (
            <>
              <Col md={3}>
                <FormGroup>
                  <Label for="distance" className="fw-bold">
                    Distance
                  </Label>
                  <Input
                    id="distance"
                    name="distance"
                    type="text"
                    defaultValue={booking?.distance}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="duration" className="fw-bold">
                    Duration
                  </Label>
                  <Input
                    id="duration"
                    name="duration"
                    type="text"
                    defaultValue={booking?.duration}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
            </>
          )}
          {booking?.serviceType === "hourly" && (
            <Col md={6}>
              <FormGroup>
                <Label for="hours" className="fw-bold">
                  Hours
                </Label>
                <Input
                  id="hours"
                  name="hours"
                  type="text"
                  defaultValue={booking?.hours}
                  disable={true}
                />
              </FormGroup>
            </Col>
          )}
        </Row>

        {booking?.transferType === "Round Trip" && (
          <>
            <h3 className="mt-4 fw-bold">Return Journey Details</h3>
            <hr />
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="retrunRouteFrom" className="fw-bold">
                    Return Pick Up Location
                  </Label>
                  <Input
                    id="retrunRouteFrom"
                    name="retrunRouteFrom"
                    type="text"
                    defaultValue={booking?.routeTo}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="retrunRouteTo" className="fw-bold">
                    Retrun Drop Off Location
                  </Label>
                  <Input
                    id="retrunRouteTo"
                    name="retrunRouteTo"
                    type="text"
                    defaultValue={booking?.routeFrom}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="returnDate" className="fw-bold">
                    Return Pickup Date & Time
                  </Label>
                  <Input
                    id="returnDate"
                    name="returnDate"
                    type="text"
                    defaultValue={booking?.returnDate}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="distance" className="fw-bold">
                    Distance
                  </Label>
                  <Input
                    id="distance"
                    name="distance"
                    type="text"
                    defaultValue={booking?.distance}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="duration" className="fw-bold">
                    Duration
                  </Label>
                  <Input
                    id="duration"
                    name="duration"
                    type="text"
                    defaultValue={booking?.duration}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        <h3 className="mt-4 fw-bold">Vehicle Details</h3>
        <hr />
        <FormGroup row>
          <Label for="name" className="fw-bold" sm={2}>
            Vehicle Name
          </Label>
          <Col sm={10}>
            <Input
              id="name"
              name="name"
              type="text"
              defaultValue={booking.vehicle?.name}
              disabled={true}
            />
          </Col>
        </FormGroup>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="passengers" className="fw-bold">
                Passengers
              </Label>
              <Input
                id="passengers"
                name="passengers"
                type="text"
                defaultValue={booking?.passengers}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="handCarry" className="fw-bold">
                No of Hand Carry
              </Label>
              <Input
                id="handCarry"
                name="handCarry"
                type="text"
                defaultValue={booking?.handCarry}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="suitcases" className="fw-bold">
                No of Suitcases
              </Label>
              <Input
                id="suitcases"
                name="suitcases"
                type="text"
                defaultValue={booking?.suitcases}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>
        {booking?.extras?.childSeatSelected ||
        booking?.extras?.champagneSelected ||
        booking?.extras?.weddingRibbonSelected ? (
          <Alert color="danger">
            <h5 className="fw-bold">
              Extras <Badge color="danger">Important</Badge>
            </h5>
            <Row>
              {booking?.extras?.childSeatSelected && (
                <>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="childSeatCount0_12" className="fw-bold">
                        Child Seat (0 - 3 Months)
                      </Label>
                      <Input
                        id="childSeatCount0_12"
                        name="childSeatCount0_12"
                        type="text"
                        defaultValue={booking.extras.childSeatCount0_12}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="childSeatCount1_4" className="fw-bold">
                        Child Seat (4 - 6 Months)
                      </Label>
                      <Input
                        id="childSeatCount1_4"
                        name="childSeatCount1_4"
                        type="text"
                        defaultValue={booking.extras.childSeatCount1_4}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="childSeatCount4_12" className="fw-bold">
                        Child Seat (6 - 12 Months)
                      </Label>
                      <Input
                        id="childSeatCount4_12"
                        name="childSeatCount4_12"
                        type="text"
                        defaultValue={booking.extras.childSeatCount4_12}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              {booking?.extras?.champagneSelected && (
                <Col md={6}>
                  <FormGroup>
                    <Label for="champagneCount" className="fw-bold">
                      Champagne
                    </Label>
                    <Input
                      id="champagneCount"
                      name="champagneCount"
                      type="text"
                      defaultValue={booking.extras.champagneCount}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
              {booking?.extras?.weddingRibbonSelected && (
                <Col md={6}>
                  <FormGroup>
                    <Label for="weddingRibbonCount" className="fw-bold">
                      Wedding Ribbon
                    </Label>
                    <Input
                      id="weddingRibbonCount"
                      name="weddingRibbonCount"
                      type="text"
                      defaultValue={booking.extras.weddingRibbonCount}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </Alert>
        ) : null}

        <h3 className="mt-4 fw-bold">Flight Details</h3>
        <hr />
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="flightDetails" className="fw-bold">
                Flight Number
              </Label>
              <Input
                id="flightDetails"
                name="flightDetails"
                type="text"
                defaultValue={booking?.flightDetails}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="airlineName" className="fw-bold">
                Airline Name
              </Label>
              <Input
                id="airlineName"
                name="airlineName"
                type="text"
                defaultValue={booking?.airlineName}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="flightArrivalTime" className="fw-bold">
                Flight Arrival Time
              </Label>
              <Input
                id="flightArrivalTime"
                name="flightArrivalTime"
                type="text"
                defaultValue={booking?.flightArrivalTime}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="flightDepartureTime" className="fw-bold">
                Flight Departure Time
              </Label>
              <Input
                id="flightDepartureTime"
                name="flightDepartureTime"
                type="text"
                defaultValue={booking?.flightDepartureTime}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="nameBoard" className="fw-bold">
                Name Board
              </Label>
              <Input
                id="nameBoard"
                name="nameBoard"
                type="text"
                defaultValue={booking?.nameBoard}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="sendDriverInside" className="fw-bold">
                Send Driver Inside
              </Label>
              <Input
                id="sendDriverInside"
                name="sendDriverInside"
                type="text"
                defaultValue={booking?.sendDriverInside}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <h3 className="mt-4 fw-bold">Payment Details</h3>
        <hr />
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="toPay" className="fw-bold">
                To Pay
              </Label>
              <Input
                id="toPay"
                name="toPay"
                type="text"
                defaultValue={booking?.toPay}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="paymentMethod" className="fw-bold">
                Payment Method
              </Label>
              <Input
                id="paymentMethod"
                name="paymentMethod"
                type="text"
                defaultValue={booking?.paymentMethod}
                disabled={true}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="transactionStatus" className="fw-bold">
                Transaction Status
              </Label>
              <Input
                id="transactionStatus"
                name="transactionStatus"
                type="text"
                defaultValue={booking?.transactionStatus}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>

        <h3 className="mt-4 fw-bold">Other Details</h3>
        <hr />
        <Row>
          <Col>
            <FormGroup>
              <Label for="additionalComments" className="fw-bold">
                Additional Comments
              </Label>
              <Input
                id="additionalComments"
                name="additionalComments"
                type="textarea"
                defaultValue={booking?.additionalComments}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default OldBookingDetail;
