const Dashboard = () => {
  return (
    <div>
      <div style={{ height: "52px" }}>
        {/* <span className="fs-1 fw-bold" style={{ color: "#fff" }}>
          Bookings
        </span> */}
        <h1 className="fw-bold" style={{ color: "#fff" }}>
          Dashboard
        </h1>
      </div>
      <hr style={{ color: "#fff", borderTop: "3px solid" }} />
      <div className="text-end my-3">
        <h2 className="text-center" style={{ color: "#fff" }}>
          COMING SOON...
        </h2>
      </div>
    </div>
  );
};

export default Dashboard;
