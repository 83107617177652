import { Link, NavLink, useNavigate } from "react-router-dom";
import { BsSpeedometer2 } from "react-icons/bs";
import { FaCarSide } from "react-icons/fa6";
import { Button } from "reactstrap";

const SideBar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div
      className="vh-100 d-flex flex-column flex-shrink-0 p-3 rounded-end-4"
      style={{ width: "280px", position: "absolute", backgroundColor: "#000" }}
    >
      <Link
        to="/"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none"
      >
        <img
          src="/images/logo.png"
          alt="Pegasus Chauffeur"
          className="img-fluid"
        />
        {/* <span className="fs-4 text-danger">Pegasus Chauffeur</span> */}
      </Link>
      <hr style={{ color: "#B88D48", borderTop: "3px solid" }} />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <NavLink to="/" className="nav-link " style={{ color: "#B88D48" }}>
            <BsSpeedometer2 className="nav-icon me-3" width={16} height={16} />
            Dashboard
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/bookings"
            className="nav-link text-decoration-none"
            style={{ color: "#B88D48" }}
          >
            <FaCarSide className="nav-icon me-3" width={16} height={16} />
            Bookings
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/old-bookings"
            className="nav-link text-decoration-none"
            style={{ color: "#B88D48" }}
          >
            <FaCarSide className="nav-icon me-3" width={16} height={16} />
            Old Bookings
          </NavLink>
        </li>
      </ul>
      <hr style={{ color: "#B88D48", borderTop: "3px solid" }} />
      <div>
        <Button
          className="w-100 border-0"
          style={{ backgroundColor: "#B88D48", color: "#000" }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default SideBar;
